<template>
  <div>
    <div>
      <!-- Header -->
      <div
        class="header py-7 py-lg-8 pt-lg-9"
        :style="{ background: this.primaryColor }"
      >
        <div class="container">
          <div class="header-body text-center mb-5">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <img :src="webLogo" class="logo-size" />
                <h1 class="text-white">Welcome!</h1>
                <p class="text-lead text-white">{{ website_name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-bottom separator-skew zindex-100">
          <svg
            x="0"
            y="0"
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon
              class="fill-secondary"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <!-- Page content -->
      <div class="container mt--8 pb-5">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-7">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-header bg-transparent pb-2">
                <div class="text-muted text-center mt-2 mb-3">
                  <h4>เข้าสู่ระบบ</h4>
                </div>
              </div>
              <div class="card-body px-lg-5 py-lg-5">
                <Form @submit="onSubmitLogin" :validation-schema="schema">
                  <base-input
                    alternative
                    name="phone"
                    addon-left-icon="ni ni-email-83"
                    placeholder="phone"
                  >
                  </base-input>

                  <base-input
                    alternative
                    name="password"
                    addon-left-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                  >
                  </base-input>

                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      >เข้าสู่ระบบ</base-button
                    >
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/services/http-common";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import Notification from "../../components/Notification.vue";

export default {
  components: {
    Form,
  },
  data() {
    return {
      primaryColor: "#1d009c",
      webLogo: "",
      website_name: "",
      schema: Yup.object({
        phone: Yup.string().required("กรอกข้อมูลให้ครบถ้วน"),
        password: Yup.string().required("กรอกข้อมูลให้ครบถ้วน"),
      }),
    };
  },
  created() {
    if (this.Auth) {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.fetchWebSetting();
  },
  methods: {
    onSubmitLogin(values) {
      HTTP.post("/affsystem/affauthen", {
        phone: values.phone,
        password: values.password,
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่"
            );
          } else {
            this.$store.dispatch("loginAuth", resp.data.token);
            this.$router.push("/dashboard");
          }
        })
        .catch((err) => {
          if (err.response) {
            this.runToast(
              "top-right",
              "danger",
              err.response.data.msg != undefined
                ? err.response.data.msg
                : "ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่"
            );
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    fetchWebSetting() {
      HTTP.get(`system/info`)
        .then((resp) => {
          if (resp.data.data != null && resp.data.data.logo != null) {
            this.webLogo = resp.data.data.logo;
            this.website_name = resp.data.data.website_name;
            this.primaryColor = resp.data.data.color1;
          }
        })
        .catch((err) => {
          this.$toast.error(
            err.data.msg != undefined
              ? err.data.msg
              : "ไม่สามารถโหลดได้ กรุณาลองใหม่"
          );
        });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
  },
};
</script>

<style>
.ct-notification .Vue-Toastification__toast--default {
  padding: 0;
  overflow: inherit;
  box-shadow: none;
  background-color: transparent;
}
.logo-size {
  width: 30%;
  height: auto;
}
</style>
