<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <base-header class="pb-6">
    <div class="row pt-4">
        <div class="col-md-2">
          <base-input
            alternative
            name="date1"
            label="ยอดของวันที่"
          >
            <template v-slot="{ focus, blur }">
              <flat-picker
                @on-open="focus"
                @on-close="blur"
                :config="{ allowInput: true, enableTime: false }"
                class="form-control datepicker"
                v-model="fromDate"
              >
              </flat-picker>
            </template>
          </base-input>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-users">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่วันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_daily }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-users">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.deposit_daily }}
              </div>
            </template>
          </stats-card>
        </div>
      
        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-users">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเกิน (100/ต่อวันที่สมัคร) วันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{  }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเกิน (300/บิลแรกของวันที่สมัคร) ในช่วงวันที่ Challenge Days วันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{  }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-users">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >รายได้สะสมเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{  }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดฝาก/ยอดถอน ของสมาชิกวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_daily }}/{{ logData.deposit_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกทั้งหมดที่มีเข้าสู่ระบบวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_login}}
              </div>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div>
      <card
        header-classes="bg-warning"
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template v-slot:header>
          <div class="row align-items-center">
            <label class="col-md-6 mb-0 text-white">
              สมาชิกที่มียอดฝาก {{ logData.deposit_daily }} คน
            </label>
            <div class="col-md-6 d-flex justify-content-end">
              <div class="form-group mb-0 mr-3">
                <el-select
                  class="select-primary pagination-select mx-2"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                  style="width: 4.5rem"
                  id="selectshow"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <base-pagination
                class="pagination-no-border mb-0 align-items-center"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </template>

        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-table
              v-loading="loading"
              :data="transformedData"
              :default-sort="{ prop: 'credit', order: 'descending' }"
              row-key="id"
              header-row-class-name="thead-light"
              Boolean="true"
            >
              <el-table-column min-width="150px" align="center" label="สมาชิก">
                <template v-slot:default="props">
                  <i class="fa fa-user text-primary mr-2"></i
                  >{{ props.row.fname }} {{ props.row.lname }}
                  <br />
                  <i class="fa fa-mobile-alt text-dark mr-2"></i>
                  {{ props.row.phone }}
                </template>
              </el-table-column>
              <el-table-column min-width="150px" align="center" label="Deposit Details">
                <template v-slot:default="props">
                  <div v-for="transaction in props.row.transactions" :key="transaction.date + transaction.time">
                    <span class="amount">{{ formatAmount(transaction.amount) }}</span>
                    <span class="date-time">วันที่: {{ transaction.date }} เวลา: {{ transaction.time }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="150px" align="center" label="Withdrawal Details">
                <template v-slot:default="props">
                  <div v-if="props.row.withdrawals && props.row.withdrawals.length > 0">
                    <div v-for="withdrawal in props.row.withdrawals" :key="withdrawal.date + withdrawal.time">
                      <span class="amount">{{ formatAmount(withdrawal.credit_up) }}</span>
                      <span class="date-time">วันที่: {{ withdrawal.date }} เวลา: {{ withdrawal.time }}</span>
                    </div>
                  </div>
                  <div v-else>
                      <span>-</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <template v-slot:footer>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด {{ total }} รายการ
              </p>
            </div>
          </div>
        </template>
      </card>
      <card
        header-classes="bg-warning"
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template v-slot:header>
          <div class="row align-items-center">
            <label class="col-md-6 mb-0 text-white">
              สมาชิกที่สมัครมาวันนี้ {{ logData.member_daily }} คน
            </label>
            <div class="col-md-6 d-flex justify-content-end">
              <div class="form-group mb-0 mr-3">
                <el-select
                  class="select-primary pagination-select mx-2"
                  v-model="pagination2.perPage"
                  placeholder="Per page"
                  style="width: 4.5rem"
                  id="selectshow"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination2.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <base-pagination
                class="pagination-no-border mb-0 align-items-center"
                v-model="pagination2.currentPage"
                :per-page="pagination2.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </template>

        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-table
              v-loading="loading"
              :data="queriedData2"
              :default-sort="{ prop: 'credit', order: 'descending' }"
              row-key="id"
              header-row-class-name="thead-light"
              Boolean="true"
            >
              <el-table-column min-width="150px" align="center" label="สมาชิก">
                <template v-slot:default="props">
                  <i class="fa fa-user text-primary mr-2"></i>
                    <span :class="{ 'highlighted-name': commonMembers.has(`${props.row.fname} ${props.row.lname}`) }">
                      {{ props.row.fname }} {{ props.row.lname }}
                    </span>
                    <br />
                    <i class="fa fa-mobile-alt text-dark mr-2"></i>{{ props.row.phone }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="150px"
                align="center"
                label="วันที่"
                prop="login_at"
                sortable
              >
                <template v-slot:default="props">
                  สมัคร : {{ props.row.create_at }}
                  <br />
                  เข้าล่าสุด : {{ props.row.login_at }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <template v-slot:footer>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                แสดง {{ from2 + 1 }} ถึง {{ to2 }} จากทั้งหมด {{ total2 }} รายการ
              </p>
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { HTTP } from "@/services/http-common";
import "sweetalert2/dist/sweetalert2.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";

import moment from "moment";

export default {
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 500, 2000, 5000],
        total: 0,
      },
      pagination2: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 500, 2000, 5000],
        total: 0,
      },
      MemberCount: "",
      transformedData: [],
      searchQuery: "",
      searchedData: [],
      searchedData2: [],
      fromDate: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      AllData: [],
      wData: [],
      AllData2: [],
      logData: "",
    };
  },
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    flatPicker,
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      (this.fromDate = moment(new Date()).format("YYYY-MM-DD HH:mm")),
        this.fetchAllData();
    }
  },
  watch: {
    fromDate() {
      this.fetchAllData();
    },
  },
  methods: {
    formatAmount(amount) {
      return Number(amount).toLocaleString("th-TH", {
        style: "currency",
        currency: "THB",
        minimumFractionDigits: 2, // Optional: You can specify the number of decimal places
      });
    },
    transformData(depositData, withdrawData) {
      const grouped = {};

      // Process deposit data
      depositData.forEach((item) => {
        const key = `${item.phone}-${item.fname}-${item.lname}`;
        if (!grouped[key]) {
          grouped[key] = { ...item, transactions: [], withdrawals: [] };
        }
        grouped[key].transactions.push({
          amount: item.amount,
          date: item.date,
          time: item.time,
        });
      });

      // Process withdrawal data
      withdrawData.forEach((item) => {
        const key = `${item.phone}-${item.fname}-${item.lname}`;
        if (!grouped[key]) {
          grouped[key] = { ...item, transactions: [], withdrawals: [] };
        }
        grouped[key].withdrawals.push({
          credit_up: item.credit_up,
          date: item.date,
          time: item.time,
        });
      });

      return Object.values(grouped);
    },
    fetchAllData() {
      HTTP.get("/affsystem/affdaily", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
        params: {
          date: this.fromDate.split(" ", 1)[0],
        },
      })
        .then((resp) => {
          if (
            resp.data.status != undefined &&
            resp.data.status == false &&
            !resp.data.status
          ) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.loading = false;
            this.AllData = resp.data.data;
            this.wData = resp.data.wdata;
            this.transformedData = this.transformData(this.AllData, this.wData);
            this.AllData2 = resp.data.data2;
            this.logData = resp.data.log;
            this.$store.dispatch("getProfile", resp.data.user);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.AllData.slice(this.from, this.to);
    },
    pagedData2() {
      return this.AllData2.slice(this.from2, this.to2);
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.AllData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    queriedData2() {
      let filteredResults = this.AllData2;
      if (this.searchQuery) {
        filteredResults = filteredResults.filter((row) => {
          let isIncluded = false;
          for (let key of this.propsToSearch) {
            let rowValue = row[key].toString();
            if (rowValue.includes(this.searchQuery)) {
              isIncluded = true;
              break; // Add this if you want to stop checking once a match is found
            }
          }
          return isIncluded;
        });
      }
      return filteredResults.slice(this.from2, this.to2);
    },
    from() {
      return (
        this.pagination.currentPage * this.pagination.perPage -
        this.pagination.perPage
      );
    },
    to() {
      return Math.min(this.from + this.pagination.perPage, this.total);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.AllData.length;
    },
    from2() {
      return (
        this.pagination2.currentPage * this.pagination2.perPage -
        this.pagination2.perPage
      );
    },
    to2() {
      return Math.min(this.from2 + this.pagination2.perPage, this.total2);
    },
    total2() {
      return this.searchedData2.length > 0
        ? this.searchedData2.length
        : this.AllData2.length;
    },
    commonMembers() {
      const registeredMembers = new Set(
        this.AllData2.map((item) => `${item.fname} ${item.lname}`)
      );
      return new Set(
        this.transformedData
          .filter((item) =>
            registeredMembers.has(`${item.fname} ${item.lname}`)
          )
          .map((item) => `${item.fname} ${item.lname}`)
      );
    },
  },
};
</script>

<style>
.amount {
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  color: #4caf50; /* Example color */
}

.date-time {
  display: inline-block;
  color: #2196f3; /* Example color */
}
.highlighted-name {
  font-weight: bold;
  color: #07dd8b; /* Choose a color that stands out */
}
</style>
