const token = localStorage.getItem("adb_token");
const initialState = token
  ? { loggedIn: true, phone: null }
  : { loggedIn: false, phone: null };

export const user = {
  state() {
    return {
      user: initialState,
      role: {},
    };
  },
  getters: {
    Auth: (state) => {
      return state.user.loggedIn;
    },
    User: (state) => {
      return state.user;
    },
    Role: (state) => {
      return state.role;
    },
  },
  actions: {
    loginAuth(context, token) {
      context.commit("loginAuth", token);
    },
    getProfile(context, user) {
      context.commit("getProfile", user);
    },
    logoutAuth(context) {
      context.commit("logoutAuth");
    },
  },
  mutations: {
    loginAuth(state, token) {
      state.user.loggedIn = true;
      localStorage.setItem("adb_token", token);
    },
    getProfile(state, user) {
      state.user.phone = user;
    },
    logoutAuth(state) {
      state.user.loggedIn = false;
      state.user.phone = null;
      localStorage.removeItem("adb_token");
    },
  },
};
