<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'ni ni-spaceship text-info',
          }"
        />
        <!-- ============================ -->
        <sidebar-item
          :link="{
            name: 'รายงานประจำวัน',
            path: '/Dailyreport',
            icon: 'ni ni-spaceship text-info',
          }"
        />
        <!-- ============================ -->
      </template>
      <template v-slot:links-after>
        <hr class="my-3" />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
import DashboardNavbar from "./DashboardNavbar.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    DashboardNavbar,
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.initScrollbar();
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
  },
};
</script>
<style lang="scss"></style>
