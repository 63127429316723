import { createRouter, createWebHistory } from "vue-router";

import store from "../store/vuex";

import DashboardLayout from "../views/Layout/DashboardLayout";
import AuthLayout from "../views/Layout/AuthLayout.vue";

// Pages
import Login from "../views/Pages/Login.vue";
// Dashboard
import Dashboard from "../views/Pages/Dashboard/Dashboard.vue";
// Dashboard
import Dailyreport from "../views/Pages/Dashboard/Dailyreport.vue";
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    name: "Dashboards",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/Dailyreport",
        name: "Dailyreport",
        components: { default: Dailyreport },
      },
    ],
  },
  {
    path: "/",
    redirect: "/",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
