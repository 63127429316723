<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'navbar-dark': type === 'default' }"
    :style="{ background: this.primaryColor }"
  >
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" src="img/autoprofile.webp" />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{
                  User.phone
                }}</span>
              </div>
            </div>
          </a>
        </template>

        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">{{ User.prefix }}</h6>
        </div>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" @click="logOut()">
          <i class="ni ni-user-run"></i>
          <span>ออกจากระบบ</span>
        </a>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import { HTTP } from "@/services/http-common";
import "sweetalert2/dist/sweetalert2.css";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  data() {
    return {
      primaryColor: "#1d009c",
    };
  },
  mounted() {
    this.fetchWebSetting();
  },
  computed: {
    ...mapGetters(["User"]),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  methods: {
    async logOut() {
      await this.$store.dispatch("logoutAuth");
      this.$router.push("/login");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    fetchWebSetting() {
      HTTP.get(`system/info`)
        .then((resp) => {
          if (resp.data.data != null && resp.data.data.color1 != null) {
            this.primaryColor = resp.data.data.color1;
          }
        })
        .catch((err) => {
          this.$toast.error(
            err.data.msg != undefined
              ? err.data.msg
              : "ไม่สามารถโหลดได้ กรุณาลองใหม่"
          );
        });
    },
  },
};
</script>
