<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row pt-4">
        <div class="col-xl-6 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-users">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกทั้งหมด</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ MemberCount }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกที่ ACTIVE / สมาชิกที่ไม่ ACTIVE (มียอดฝากจากบิลสุดท้ายไม่เกิน5วัน)</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ MemberCount }} / {{ MemberCount }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่เดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.deposit_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเกิน (100/ต่อวันที่สมัคร) เดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.deposit_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเกิน (300/บิลแรกของวันที่สมัคร) ในช่วงวันที่ Challenge Days เดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.deposit_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดฝาก/ยอดถอน ของสมาชิกเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_daily }}/{{ logData.deposit_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ไม่มียอดฝาก เดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_daily }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-6 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-users">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่อาทิตย์นี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ MemberCount }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากอาทิตย์นี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_login}}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเกิน (100/ต่อวันที่สมัคร) อาทิตย์นี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเกิน (300/บิลแรกของวันที่สมัคร) ในช่วงวันที่ Challenge Days อาทิตย์นี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.deposit_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดฝาก/ยอดถอน ของสมาชิกอาทิตย์นี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_daily }}/{{ logData.deposit_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ไม่มียอดฝาก อาทิตย์นี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_daily }}
              </div>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-xl-6 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-users">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่วันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ MemberCount }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_login}}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเกิน (100/ต่อวันที่สมัคร) วันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเกิน (300/บิลแรกของวันที่สมัคร) ในช่วงวันที่ Challenge Days วันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.deposit_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดฝาก/ยอดถอน ของสมาชิกวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_daily }}/{{ logData.deposit_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกเข้าสู่ระบบวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_login}}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-6 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-users">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >รายได้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ MemberCount }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_login}}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเกิน (100/ต่อวันที่สมัคร) วันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่ฝากเกิน (300/บิลแรกของวันที่สมัคร) ในช่วงวันที่ Challenge Days วันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.deposit_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกเข้าสู่ระบบวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ logData.member_login}}
              </div>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div>
      <card
        header-classes="bg-warning"
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template v-slot:header>
          <div class="row align-items-center">
            <label class="col-md-6 mb-0 text-white">
              สมาชิกพันธมิตร {{ MemberCount }} คน
            </label>
            <div class="col-md-6 d-flex justify-content-end">
              <div class="form-group mb-0 mr-3">
                <el-select
                  class="select-primary pagination-select mx-2"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                  style="width: 4.5rem"
                  id="selectshow"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <base-pagination
                class="pagination-no-border mb-0 align-items-center"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </template>

        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-table
              v-loading="loading"
              :data="queriedData"
              :default-sort="{ prop: 'credit', order: 'descending' }"
              row-key="id"
              header-row-class-name="thead-light"
              Boolean="true"
            >
              <el-table-column min-width="150px" align="center" label="สมาชิก">
                <template v-slot:default="props">
                  <i class="fa fa-user text-primary mr-2"></i
                  >{{ props.row.fname }} {{ props.row.lname }}
                  <br />
                  <i class="fa fa-mobile-alt text-dark mr-2"></i>
                  {{ props.row.phone }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="150px"
                align="center"
                label="วันที่"
                prop="login_at"
                sortable
              >
                <template v-slot:default="props">
                  สมัคร : {{ props.row.create_at }}
                  <br />
                  เข้าล่าสุด : {{ props.row.login_at }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <template v-slot:footer>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด {{ total }} รายการ
              </p>
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { HTTP } from "@/services/http-common";
import "sweetalert2/dist/sweetalert2.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";

// Charts
import Chart from "chart.js";
import moment from "moment";

export default {
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 500, 2000, 5000],
        total: 0,
      },
      MemberCount: "",
      searchedData: [],
      fromDate: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      AllData: [],
      logData: "",
      Chart: {
        ChartID: "salesChart",
        Data: {
          allData: [[], [], [], []],
        },
      },
    };
  },
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      (this.fromDate = moment(new Date()).format("YYYY-MM-DD HH:mm")),
        this.fetchAllData();
      this.fetchChart();
    }
  },
  methods: {
    fetchAllData() {
      HTTP.get("/affsystem/affmember", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
        params: {
          date: this.fromDate.split(" ", 1)[0],
        },
      })
        .then((resp) => {
          if (
            resp.data.status != undefined &&
            resp.data.status == false &&
            !resp.data.status
          ) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.loading = false;
            this.AllData = resp.data.data;
            this.logData = resp.data.log;
            this.MemberCount = this.AllData.length; 
            this.$store.dispatch("getProfile", resp.data.user);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    fetchChart() {
      HTTP.get("/admin/chart")
        .then((resp) => {
          this.fetchChartData(
            resp.data.depositSevenDay,
            resp.data.withdrawSevenDay
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchChartData(Deposit, Withdraw) {
      this.Chart.Data.allData[0] = Withdraw.map((e) =>
        moment(e.temporary_date).format("DD/MM/YYYY")
      );
      this.Chart.Data.allData[1] = Withdraw.map((e) => +e.withdraw);
      this.Chart.Data.allData[2] = Deposit.map((e) => +e.deposit);

      let profits = [];
      for (let index = 0; index < Deposit.length; ++index) {
        let profit_result = Deposit[index].deposit - Withdraw[index].withdraw;
        profits.push(profit_result);
      }
      this.Chart.Data.allData[3] = profits;

      this.createChart();
    },
    createChart() {
      const ctx = document.getElementById(this.Chart.ChartID).getContext("2d");

      new Chart(ctx, {
        type: "line",
        data: {
          labels: this.Chart.Data.allData[0],
          datasets: [
            {
              label: "ยอดถอน",
              tension: 0.4,
              borderWidth: 4,
              borderColor: "red",
              pointRadius: 0,
              backgroundColor: "transparent",
              data: this.Chart.Data.allData[1],
              barPercentage: 1.6,
            },
            {
              label: "ยอดฝาก",
              tension: 0.4,
              borderWidth: 4,
              borderColor: "blue",
              pointRadius: 0,
              backgroundColor: "transparent",
              data: this.Chart.Data.allData[2],
              barPercentage: 1.6,
            },
            {
              label: "กำไร",
              tension: 0.4,
              borderWidth: 4,
              borderColor: "green",
              pointRadius: 0,
              backgroundColor: "transparent",
              data: this.Chart.Data.allData[3],
              barPercentage: 1.6,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: "#C7CACD",
                  zeroLineColor: "#C7CACD",
                },
                ticks: {
                  beginAtZero: true,
                  padding: 20,
                  fontColor: "black",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: "transparent",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 10,
                  fontColor: "black",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              },
            ],
          },
          layout: {
            padding: 0,
          },
        },
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.AllData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.AllData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.AllData.length;
    },
  },
};
</script>

<style></style>
